body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

/* change back */
:root {
  font-size: 12px; 
}
@media (max-width: 800px) {
  :root {
    font-size: 10px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Mocha_Mattari";
  src: local("Mocha_Mattari"), url(./fonts/Mocha_Mattari.otf) format("opentype");
}
@font-face {
  font-family: "TisaSansPro-Bold";
  src: local("TisaSansPro-Bold"),
    url(./fonts/TisaSansPro-Bold.otf) format("opentype");
}
@font-face {
  font-family: "TisaSansPro";
  src: local("TisaSansPro"),
    url(./fonts/TisaSansPro.otf) format("opentype");
}

h1 {
  font-family: "Mocha_Mattari";
  font-weight: 100;
  margin: 0;
}

h2 {
  font-family: "TisaSansPro-Bold";
  margin: 0;
  font-size: 3em;
}

p {
  font-family: "TisaSansPro";
  margin: 0;
  font-size: 1em;
}

.link {
  color: black;
  text-decoration: none;
  font-family: "TisaSansPro"
}
.link:hover {
  opacity: 0.7;
}