.sectionContainer.books {
  flex-direction: row;
  align-self: center;
  padding-bottom: 7em;
}
.book-description {
  text-align: left;
  display: flex;

  flex-direction: column;
  width: 50%;
}
@media(max-width: 800px) {
  .book-description {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.background-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../assets/Waves.jpg"),
    radial-gradient(circle at center, #00000092 50%, #ffffff20);
  background-repeat: no-repeat;

  background-blend-mode: multiply;
  animation: movingbackground 30s linear infinite alternate;
  
}
.background-image.books {
  background: url("../../assets/Forest.jpg"),
    radial-gradient(circle at center, #000000cc 50%, #ffffff20);
}

.background-image.books.mc {
  background: url("../../assets/Forest.jpg"),
    radial-gradient(circle at center, #000000cc 50%, #ffffff20);
}

@keyframes movingbackground {
  0% {
    background-position: left;
    background-size: 150%;
  }
  100% {
    background-position: right;
    background-size: 125%;
  }
}

@media(max-width: 800px) {
  .background-image {
    background-size: cover;
    animation: none;  
  }
}

.grid-item.books {
  background: radial-gradient(black 30%, transparent);
  border-radius: 8%;
  padding: 2em;
}
.preorder-image-container {
  transition: box-shadow 1s, transform 0.6s;
  border-radius: 8%;


  display: flex;
  align-items: center;
  justify-content: center;
}

.preorder-image-container:hover {
  cursor: pointer;
  box-shadow: 2px 2px 8px 1px #1f9098;
  transform: scale(1.1);
}

.preorder-image:hover {
  transform: scale(1);
  box-shadow: none;
}

.review-author.hoverable:hover {
  opacity: 0.7;
  cursor: pointer;
}

.grid {
  will-change: transform;
  transition: transform 2s;
  min-width: 350px;
}

.grid.pop {
  transform: scale(1.1);
}

.book-description.reviews {
  width: 60%;
  min-width: 350px;
}
