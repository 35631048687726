.email:hover {
  cursor: pointer;
}

.highlight-div {
  border-radius: 50px;
  padding: 1em 3em;
}
.highlight-div.original-author {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  display: flex;
  padding-right: 5em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

}

.author-image {
  margin-left: auto; 
  height: auto;
  width: 15%;
}

@media (max-width: 800px) {
  .highlight-div {
    border-radius: 0;

    padding-bottom: 2em;

    flex: 1;
  }
  .highlight-div.original-author {
    border-top-right-radius: 0px;
    padding: 1em 0px 2em 0px;
    border-bottom-left-radius: 50px;
    align-items: center;
  }
  .author-image {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;

    width: 100%;
  }
}
