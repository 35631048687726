.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding: 5em 0 1em 0;
}

.footer-button {
  transition: all 1s;
}

.footer-button:hover {
  cursor: pointer;
  font-size: 1.05em;
  color: #007FAE;

}