.header {
  font-size: 3.5em;
  font-weight: 100;
}
.logo-wrapper {
  cursor: pointer;
}

.logo-wrapper:hover {
  opacity: 0.7;
}

.link {
  padding: 0.5em 1em;
}

.link.focused {
  color: #e9465e;
  border: 1px #e9465e solid;
}

.drawer {
  width: 100%;
}

.to-load-in {
  opacity: 0;
  will-change: opacity;
  transition: opacity 2s ease-out;
  background-color: #E3F1F2;
}

.to-load-in.loadedIn {
  opacity: 1;
}


.book.link {
  position: relative;
  display: inline-block;
}

.book.link:hover .dropdown-content {
  display: block;
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  font-family: "TisaSansPro";
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}


