.section-container.contact {
  display: flex;
  width: 60dvw;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 800px) {
  .section-container.contact {
    width: 100dvw;
  }
}

.container.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form {
  align-items: center;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
    cursor:pointer;
}
