.author-bio {
  font-size: 1.4em;
  font-family: "TisaSansPro";
  line-height: 1.8em;
  text-align: left;
  margin-bottom: 1em;
}

.bio-header {
  align-self: flex-start;
  font-size: 3em;
  margin-bottom: 0.5em;
  color: #e9465e;
}

.sectionContainer.bio {
  flex-direction: row;
  padding-bottom: 3em;
  justify-content: center;
  flex-wrap: wrap;
}

.bio-header.two {
  margin-bottom: 0em;
}

.sectionContainer.bio.two {
  gap: 1em;
  margin-top: 4em;
}

.pre-order-container.bio {
  margin-top: auto;
  color: #e9465e;
}

.link.bio {
  padding: 0;
}

.link.bio:hover {
  cursor: pointer;
}