.social-list {
    width: 250px;
}

.icon {
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1) .5s;
}
.icon:hover {
    transform: scale(1.05) translateY(-.1em);
    opacity: 0.7;
}