.cover {
  max-height: 70svh;
  min-width: 20dvw;
  max-width: 25dvw;
  height: auto;
  width: auto;

  align-self: center;
}

.cover.author {
  height: auto;
  cursor: pointer;
}
.sectionContainer {
  width: 60dvw;
  justify-content: center;
  display: flex;
  flex-direction: row-reverse;
  gap: 5dvw;
  flex-wrap: wrap;
}

.dynamic-width {
  width: 60dvw !important;
}

.sectionContainer.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1400px) {
  .sectionContainer {
    width: 68dvw;
  }
  .cover {
  }
}

@media (max-width: 1200px) {
  .cover {
    max-width: 94dvw;
  }
}

@media (max-width: 800px) {
  .sectionContainer {
    width: 94dvw;
  }
  .dynamic-width {
    width: 94dvw !important;
  }
}
.sectionContainer.anim {
  flex-direction: column;
  gap: 0dvw;
  flex-wrap: nowrap;
  justify-content: normal;
}

.description {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.summary {
  font-size: 1.8em;

  margin-top: auto;
  text-align: left;
}

.summary.long {
  margin-bottom: auto;
  font-size: 1.1em;
}

.countdown {
  font-size: 8em;
  font-family: "Mocha_Mattari";
  color: #e9465e;
}

.preorder-list {
  display: flex;
  flex-direction: row;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: center;
}

.preorder-image {
  height: 2em;
  border-radius: 8px;
  transition: transform 600ms, box-shadow 1s;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.no-greyscale {
  filter: none;
  -webkit-filter: none;
}
.preorder-image-container {
  transition: box-shadow 1s, transform 0.6s;
  /* background-color: lightgrey; */
  border-radius: 8%;
}

.preorder-image-container:hover {
  cursor: pointer;
  box-shadow: 2px 2px 8px 1px #1f9098;
  transform: scale(1.1);
}

.preorder-image:hover {
  transform: scale(1);
  box-shadow: none;
}

.container {
  min-height: 100svh;
  opacity: 0;
  will-change: opacity;
  transition: opacity 2s ease-out;
  overflow-x: hidden;
}

.container.loadedIn {
  opacity: 1;
}

.pre-order-container {
  background: linear-gradient(45deg, #1f909820 50%, #ffffff, #1f909820 58%);
  box-shadow: 2px 2px 20px 1px #e9465e72;
  background-size: 400%;
  animation: pre-order-anim 5s infinite;
}

@keyframes pre-order-anim {
  0% {
    background-position: left;
  }
  70% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

.container.anim {
  position: absolute;
  z-index: 3;
  background-color: white;
  animation: bg-fade-out 10s forwards;
  animation-delay: 2s;
  opacity: 1;
}

@keyframes bg-fade-out {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
    z-index: 3;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

.cover.forest {
  height: 70dvh;
  position: absolute;
  right: 10%;
  z-index: 2;
  animation: forest-animation 7s forwards;
  animation-delay: 2s;
}

.cover.waves {
  height: 70dvh;
  left: 10%;
  z-index: 1;
  position: absolute;
  animation: waves-animation 7s forwards;
  animation-delay: 2s;
}

.cover.forest.ribbon {
  opacity: 0;
  animation: forest-ribbon-animation 7s forwards;
  animation-delay: 2s;
}

.cover.waves.ribbon {
  opacity: 0;
  animation: waves-ribbon-animation 7s forwards;
  animation-delay: 2s;
}

.cover.background {
  height: 70dvh;
  position: absolute;
  right: auto;
  left: auto;
  transform: translateX(-50%);
  opacity: 1;
}

.cover.text {
  position: absolute;
  right: auto;
  left: auto;
  z-index: 2;
  transform: translateX(-50%);
  animation: text-animation 9s forwards;
  animation-delay: 2s;
  opacity: 0;
}

.anim-container {
  width: 60dvw;
  align-self: center;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

@media (max-width: 800px) {
  .anim-container {
    width: 60dvw;
    align-self: center;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    animation: none;
  }
}

@keyframes anim-container {
  0% {
    right: 50%;
    transform: translateX(50%);
    opacity: 1;
  }
  70% {
    right: 50%;
    transform: translateX(50%);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  99% {
    right: 0%;

    opacity: 1;
  }
  100% {
    right: 0%;
    opacity: 1;
  }
}

@keyframes text-animation {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes forest-animation {
  0% {
    opacity: 1;

    right: 10%;
    transform: translateX(0);
  }
  20% {
    opacity: 1;

    right: 10%;
    transform: translateX(0);
  }
  55% {
    opacity: 0.5;
  }

  70% {
    opacity: 0.5;
    right: 50%;
    transform: translateX(50%);
  }
  100% {
    opacity: 0;
    right: 50%;
    transform: translateX(50%);
  }
}
@keyframes waves-animation {
  0% {
    opacity: 1;
    left: 10%;
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    left: 10%;
    transform: translateX(0);
  }
  55% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
  }
  100% {
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@keyframes forest-ribbon-animation {
  0% {
    opacity: 0;
    right: 10%;
    transform: translateX(0);
  }
  20% {
    opacity: 0;
    right: 10%;
    transform: translateX(0);
  }
  60% {
    opacity: 0.2;
  }
  70% {
    opacity: 0.5;
    right: 50%;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    right: 50%;
    transform: translateX(50%);
  }
}

@keyframes waves-ribbon-animation {
  0% {
    opacity: 0;
    left: 10%;
    transform: translateX(0);
  }
  20% {
    opacity: 0;
    left: 10%;
    transform: translateX(0);
  }
  60% {
    opacity: 0.2;
  }
  70% {
    opacity: 0.5;
    left: 50%;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
  }
}

.button.read-more {
  margin-bottom: auto;
  transition: all 1s;
  border: 2px #1f9098 solid;
}

.button.read-more:hover {
  background-color: white;
  color: #1f9098;
}
