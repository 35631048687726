.module-container.news {
  padding: 1em;
  border-radius: 10px;
  background-color: #1f909820;
  width: 100%;
  transition: all 1s;
}

.module-container.news.news-link:hover {
  cursor: pointer;
  box-shadow: rgba(233, 70, 94, 0.25) 0px 50px 100px -20px,
    rgba(59, 1, 1, 0.3) 0px 30px 60px -30px,
    rgba(233, 70, 94, 0.35) 0px -2px 6px 0px inset;

  transform: scale(1.01);
}
